<template>
  <div class="container">
    <el-form :model="form" :rules="rules" label-position="top" ref="form">
      <el-form-item label="活动名称" class="width300" prop="title">
        <el-input type="text" placeholder="请输入活动名称" v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="活动区域">
        <template v-if="!$route.query.id">
          <div
            v-for="(item,index) in areas"
            class="flex mb-15"
            style="align-item:center"
            :key="index"
          >
            <Area @change="areaChange($event, index)" />
            <el-button type="text" @click="addArea" v-if="index === 0" class="ml-15 mb-15">增加一个</el-button>
            <el-button
              type="text"
              @click="delArea(index)"
              v-if="areas.length > 1"
              class="ml-15 mb-15"
            >删除</el-button>
          </div>
        </template>

        <Area v-model="areaAddress" @change="addressChange" v-else />
      </el-form-item>
      <el-form-item label="活动类型" required>
        <el-radio-group v-model="form.marketer_type">
          <el-radio label="RATION">定额红包</el-radio>
          <el-radio label="RANDOM">随机红包</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="显示字段">
        <div>总金额：{{ totalMoney }}元</div>
        <el-row v-for="(item,index) in form.money_configs" class="mb-15" type="flex" :key="index">
          <span>金额{{ index + 1 }}：</span>
          <el-form-item
            :prop="'money_configs.' + index + '.money'"
            :key="index"
            :rules="[
              { required: true, message: '请输入金额', trigger: 'change' },
              { validator: validatePass, trigger: 'change' }
            ]"
          >
            <el-input v-model="item.money" type="number" class="mr-15" style="width:100px"></el-input>
          </el-form-item>

          <span>数量：</span>
          <el-form-item
            :prop="'money_configs.' + index + '.num'"
            :key="'num' + index"
            :rules="[
              { required: true, message: '请输入数量', trigger: 'change' }
            ]"
          >
            <el-input v-model.number="item.num" class="mr-15" :min="1" style="width:100px"></el-input>
            <span class="mr-15">个 (小计：{{ ((item.money * 100 * item.num) / 100).toFixed(2) }}元)</span>
          </el-form-item>

          <el-button size="mini" type="primary" v-if="index === 0" @click="addMoney()">增加一个</el-button>
          <el-button size="mini" type="primary" @click.prevent="delMoney(index)">删除</el-button>
        </el-row>
      </el-form-item>
      <el-form-item label="领取先登记" prop="is_register">
        <el-radio-group v-model="form.is_register">
          <el-radio :label="false">不需要</el-radio>
          <el-radio :label="true">需要</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="单个码可领取次数" prop="code_max_receive" class="width300">
        <el-input type="text" placeholder="请输入单个码可领取次数" v-model.number="form.code_max_receive"></el-input>
        <p class="input-tip">不填则不限制，请直接填数字</p>
      </el-form-item>
      <el-form-item label="每天每员工可派发个数" prop="total_day_issue" class="width300">
        <el-input type="text" placeholder="请输入每天每员工可派发个数" v-model.number="form.total_day_issue"></el-input>
        <p class="input-tip">不填则不限制，请直接填数字</p>
      </el-form-item>
      <el-form-item label="每员工累计可派发个数" prop="total_issue" class="width300">
        <el-input type="text" placeholder="请输入每员工累计可派发个数" v-model.number="form.total_issue"></el-input>
        <p class="input-tip">不填则不限制，请直接填数字</p>
      </el-form-item>
      <el-form-item label="每用户累计可领取次数" prop="total_receive" class="width300">
        <el-input type="text" placeholder="请输入每用户累计可领取次数" v-model.number="form.total_receive"></el-input>
        <p class="input-tip">不填则不限制，请直接填数字</p>
      </el-form-item>
      <el-form-item label="标题栏色号" class="width300">
        <el-input type="text" placeholder="请输入标题栏色号" v-model="form.title_color"></el-input>
      </el-form-item>
      <el-form-item label="发放主体简称" class="width300">
        <el-input type="text" placeholder="请输入发放主体简称" v-model="form.subject"></el-input>
      </el-form-item>

      <el-form-item label="封面图片">
        <ImgUpload
          :fileList="imgList"
          pixel="用于页面配图，建议640*1100像素"
          multiple
          v-model="form.marketer_bg_img_ids"
        />
      </el-form-item>
      <el-form-item label="发放主体LOGO">
        <ImgUpload :fileList="imgList2" pixel="用于页面配图，建议200*200像素" v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="领取页面背景">
        <ImgUpload
          :fileList="imgList3"
          pixel="用于页面配图，建议200*200像素"
          v-model="form.receive_bg_img_id"
        />
      </el-form-item>
      <el-form-item label="日期规则" prop="started_at">
        <WxDate @change="dateChange" :dateRule="dateRule" ref="wxDate" />
      </el-form-item>
      <el-form-item label="是否限制员工领取" required>
        <el-radio-group v-model="form.is_marketer_restrict_receive">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="员工是否可以直接派发">
        <el-switch v-model="form.is_marketer_issue"></el-switch>
      </el-form-item>
      <el-form-item label="广告语" class="width300">
        <el-input type="textarea" placeholder="请输入广告语" v-model="form.slogan"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')">立即{{ form.id ? '修改' : '创建' }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// setActivity
import { getActivity, setActivity } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import WxDate from '@/components/WxDate'
import Area from '@/components/Area'
import { getImgId } from '@/utils'
export default {
  name: 'SetSendPacket',
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入金额'))
      } else {
        if (value > 700) {
          callback(new Error('金额不能大于700'))
        } else {
          callback()
        }
      }
    }
    return {
      validatePass: validatePass,
      areas: [{ province: '', city: '', district: '', net: '' }],
      dateRule: {},
      imgList: [],
      imgList2: [],
      imgList3: [],
      dateTime: [],
      areaAddress: {},
      form: {
        money_configs: [{
          money: 1,
          num: 10
        }],
        total_day_issue: 1,
        total_issue: 1,
        total_receive: 1,
        marketer_type: 'RATION',
        is_register: false,
        register_info: [],
        type: 'MARKETER',
        max_money: '',
        subject: '',
        title_color: '',
        title: '',
        started_at: '',
        ended_at: '',
        banner_img_id: '',
        img_id: '',
        imgData: {},
        activity_id: '',
        list_province: '',
        list_city: '',
        list_district: '',
        list_net: '',
        time_rule: 'ANY',
        time_rule_day: [],
        time_rule_time: [],
        time_rule_week: [1, 2, 3, 4, 5, 6, 7],
        is_marketer_restrict_receive: 1,
        receive_bg_img_id: '',
        slogan: '',
        is_marketer_issue: false,
        marketer_bg_img_ids: [], // 封面图片id
        code_max_receive: 1
      },
      rules: {
        marketer_type: [
          { required: true, message: '请选择活动类型', trigger: 'blur' }
        ],
        is_register: [
          { required: true, message: '请选择是否需登记', trigger: 'blur' }
        ],
        participate_num: [
          { required: true, message: '请输入每日参与的总次数', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        started_at: [
          { required: true, message: '选择活动日期', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    totalMoney () {
      let total = 0
      this.form.money_configs.map(item => {
        total += item.money * 100 * item.num
      })
      return (total / 100).toFixed(2)
    }
  },
  components: {
    Area,
    ImgUpload,
    WxDate
  },
  methods: {
    delArea (index) {
      this.areas.splice(index, 1)
    },
    addArea () {
      this.areas.push({
        province: '',
        city: '',
        district: '',
        net: ''
      })
    },
    delMoney (index) {
      if (this.form.money_configs.length > 1) {
        this.form.money_configs.splice(index, 1)
      } else {
        this.$message.error('显示字段最少为一项')
      }
    },
    addMoney () {
      if (this.form.money_configs.length < 8) {
        this.form.money_configs.push({
          money: 1,
          num: 10
        })
      } else {
        this.$message.error('最多支持8个奖品')
      }
    },
    dateChange (e) {
      this.form = Object.assign(this.form, e)
    },
    addressChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    areaChange (address, index) {
      this.$set(this.areas, index, address)
    },
    // 批量创建
    batchSubmit () {

    },
    submitForm (formName) {
      const wxDateStatus = this.$refs.wxDate.validate()
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (wxDateStatus) {
            const form = JSON.parse(JSON.stringify(this.form))
            form.total_money = this.totalMoney * 100
            form.money_configs.map(item => {
              item.money = Number((item.money * 100).toFixed(0))
            })
            if (form.id) {
              const res = await setActivity(form)
              if (res.meta.code === 0) {
                this.$notify({
                  title: '成功',
                  message: '修改成功',
                  type: 'success'
                })
                this.$router.push('sendPacket')
              } else {
                this.$message.error(res.meta.msg)
              }
            } else {
              let count = this.areas.length
              this.$confirm(`即将创建${this.areas.length}个活动,是否继续`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.areas.map(async (area) => {
                  const params = Object.assign({}, form)
                  params.list_province = area.province
                  params.list_city = area.city
                  params.list_district = area.district
                  params.list_net = area.net
                  const res = await setActivity(params)
                  count--
                  if (res.meta.code === 0) {
                    this.$notify({
                      title: '成功',
                      message: '创建成功',
                      type: 'success'
                    })
                  } else {
                    this.$notify({
                      title: '失败',
                      message: res.meta.msg,
                      type: 'error'
                    })
                  }
                  if (count === 0) {
                    this.$router.push('sendPacket')
                  }
                })
              })
            }
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity

        activity.activity_id = id
        activity.marketer_bg_img_ids = getImgId(activity.bg_images_data)
        this.imgList = activity.bg_images_data
        activity.img_id = getImgId(activity.img_data)
        activity.img_data && this.$set(this.imgList2, 0, activity.img_data)
        activity.receive_bg_img_id = getImgId(activity.receive_bg_img_data)
        activity.receive_bg_img_data && this.$set(this.imgList3, 0, activity.receive_bg_img_data)
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.dateRule = {
          time_rule: activity.time_rule,
          started_at: activity.started_at,
          ended_at: activity.ended_at,
          time_rule_week: activity.time_rule_week,
          time_rule_time: activity.time_rule_time,
          time_rule_day: activity.time_rule_day
        }
        activity.money_configs.map(item => {
          item.money = item.money / 100
        })
        this.form = activity
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
